// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Inputs from './inputs';
import Filters from './filters';
import Results from './results';
import { inject, observer } from 'mobx-react';
import type { SegmentAxis } from '../types';
import { difference, get } from 'lodash';
import type { DeetectResult } from '@deecision/deefind-types/client';
import { isRelationMode } from './results/list/specs';

const FILTERS = {
    covid19: { risk: 3, potential: 1, potentialCovid19: 0, proximity: 3, completeness: 25, accuracy: 0 },
    segment: { risk: 3, potential: 1, proximity: 3, completeness: 25, accuracy: 0 },
    coopt: { risk: 3, potential: 1, proximity: 3, completeness: 25, accuracy: 0 },
    family: { risk: 3, potential: 1, proximity: 3, completeness: 25, accuracy: 0 },
    predict: { risk: 3, probability: 0, currentHorizon: 1, potentialCashout: 0, expectedValue: 0, predictType: 'all' },
    upsell: { risk: 3, upsell: 0, upsellPrct: 1, upsellMethod: 'potential', completeness: 0 },
};

const AXES = { x: 'risk', y: 'potential', extra: 'proximity' };

const styles = {
    container: {
        display: 'flex',
        alignItems: 'space-between',
    },
    inputs: {
        width: '250px',
    },
    filters: {
        width: '300px',
        margin: '0 50px',
    },
    results: {
        width: '750px',
    },
};

@inject('queue')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work';

    static propTypes = {
        state: PropTypes.object.isRequired,
        queue: PropTypes.object.isRequired,
        compute: PropTypes.func.isRequired,
        filters: PropTypes.arrayOf(PropTypes.string).isRequired,
        axes: PropTypes.object,
        style: PropTypes.object,
        loop: PropTypes.bool,
    };

    static contextTypes = {
        setStage: PropTypes.func.isRequired,
    };

    state = { filters: null, segment: null, coopt: null };

    dispose1: ?Function;
    dispose2: ?Function;

    UNSAFE_componentWillMount() {
        this.dispose1 = this.props.queue.watchStatus(this.getScope());
        this.dispose2 = this.props.queue.watchResults(this.getScope());
    }

    UNSAFE_componentWillReceiveProps(props: Object) {
        if (props.state.id !== this.props.state.id) {
            if (this.dispose1) this.dispose1();
            if (this.dispose2) this.dispose2();

            this.dispose1 = this.props.queue.watchStatus(this.getScope(props.state));
            this.dispose2 = this.props.queue.watchResults(this.getScope(props.state));
        }
    }

    componentWillUnmount() {
        if (this.dispose1) this.dispose1();
        if (this.dispose2) this.dispose2();
    }

    getScope(state?: Object) {
        state = state || this.props.state;
        return { service: 'deefind', consumer: 'deetect.' + state.tool, batch: state.id };
    }

    render() {
        const scope = this.getScope();
        const source = this.props.state.source || 'person';
        const filters = this.state.filters || FILTERS[this.props.state.tool];
        const axes = Object.assign({}, AXES, this.props.axes || {});
        const status = this.props.queue.getStatus(scope).get();
        const results = this.props.queue.getResults(scope).get() || [];

        for (const key of ['x', 'y', 'extra']) {
            if (this.props.state.axes[key]) {
                axes[key] = this.props.state.axes[key];
            }
        }

        if (!filters.type) filters.type = source;

        const { counts, exports } = this.props.compute(results, filters, axes, source);

        console.log('-- deetect -->', { source, filters, axes, status, results, counts, exports });

        return (
            <div style={Object.assign({}, styles.container, this.props.style)}>
                <Inputs
                    queue={this.props.queue}
                    scope={this.getScope()}
                    status={status}
                    counts={counts}
                    results={results}
                    style={styles.inputs}
                    type={filters.type}
                    onType={type => {
                        const next = Object.assign({}, filters, { type });
                        this.state.filters = next;
                        this.context.setStage({ filters: next });
                    }}
                />
                <Filters
                    source={this.props.state.source}
                    filters={filters}
                    counts={counts}
                    exports={exports}
                    enabled={this.props.filters}
                    onScores={filters => this.setState({ filters })}
                    onSave={filters => this.context.setStage({ filters })}
                    style={styles.filters}
                    tool={this.props.state.tool}
                />
                <Results
                    ref="results"
                    counts={counts}
                    proximity={['coopt'].includes(this.props.state.tool)}
                    axes={axes}
                    filters={filters}
                    onAxis={(name: string, value: SegmentAxis) => this.updateAxis(axes, name, value)}
                    style={styles.results}
                    onOpen={(ids: string[]) => {
                        this.refs['results'].open({
                            mode: this.props.state.tool,
                            source: source,
                            target: filters.type,
                            results: this.makeResults(results, ids),
                        });
                    }}
                />
            </div>
        );
    }

    makeResults(results: Object[], ids: string[]): Array<{ source: DeetectResult, target?: DeetectResult }> {
        if (!isRelationMode(this.props.state.tool) && !get(results[0], 'output.relations')) {
            return results
                .filter(result => (ids || []).includes(get(result, 'output.result.id')))
                .map(result => ({ source: get(result, 'output.result') }));
        }

        const outputs = [];

        for (const result of results) {
            for (const relation of get(result, 'output.relations', [])) {
                if (ids.includes(relation.id)) {
                    outputs.push({ source: get(result, 'output.result'), target: relation });
                }
            }
            if (this.props.state.tool === 'upsell') {
                outputs.push({ source: get(result, 'output.result') });
            }
        }

        return outputs;
    }

    updateAxis(axes: Object, name: string, value: SegmentAxis) {
        axes[name] = value;

        const missing = difference(Object.values(AXES), Object.values(axes))[0];

        for (const key of Object.keys(AXES)) {
            if (key !== name && axes[key] === value) {
                axes[key] = missing;
            }
        }

        this.context.setStage({ axes });
    }
}
