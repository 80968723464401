import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { forEach } from 'lodash';
import uniq from 'lodash/uniq';
import { renderFilters, filtersList, subfiltersList, parseObject, getFilters } from './utils';
import FormGroup from '@material-ui/core/FormGroup';

const styles = {
    box: {
        border: '10px',
        top: '0px',
        boxSizing: 'border-box',
        color: 'white',
        display: 'flex',
        float: 'left',
        cursor: 'pointer',
        textDecoration: 'none',
        margin: '0px',
        marginRight: '10px',
        padding: '0px',
        outline: 'none',
        position: 'relative',
        height: '40px',
        borderRadius: '2px',
        textAlign: 'center',
        marginBottom: '30px',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
        zIndex: '1',
    },
    icon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fill: 'white',
        marginRight: '10px',
    },
    notHover: {
        transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    },
    hover: {
        transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
    },
    btn: {
        display: 'flex',
        float: 'left',
    },
    circle: {
        display: 'flex',
        float: 'left',
        color: 'blue',
        margin: '3px',
        marginLeft: '6px',
    },
    filters: {
        marginBottom: '20px',
    },
    checkbox: {
        margin: '5px',
        paddingRight: '12px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '3px',
        backgroundColor: 'rgb(245, 245, 245)',
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.history.download';

    static propTypes = {
        list: PropTypes.object.isRequired,
        entity: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            modal: null,
            hover: false,
            loader: false,
            filter: '',
            filterDisp: undefined,
            displayed: false,
            filters: _.cloneDeep(filtersList),
            subfilters: _.cloneDeep(subfiltersList),
        };
        this.baseState = _.cloneDeep(this.state);
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.entity.id !== this.props.entity.id) {
            this.setState(_.cloneDeep(this.baseState));
        }
    }

    handleChange = (event) => {
        let state = { ...this.state.filters };
        state[event.target.name][0] = event.target.checked;
        this.setState({ filters : state });
    };

    handleSubChange = (event) => {
        let state = { ...this.state.subfilters };
        state[event.target.name][0] = event.target.checked;
        this.setState({ subfilters : state });
    };

    handleMouseHover = (filter) => {
        this.setState({ filterDisp: filter })
    }

    async makeZip(zip, by, list) {
        let x = 0;
        return new Promise(resolve => {
            for (let k = 0; k < (list).length; k++) {
                fetch('https://cors-anywhere.herokuapp.com/' + list[k].urlPdf, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Content-Type': 'application/pdf',
                        'Access-Control-Allow-Origin': 'http://127.0.0.1:8090',
                    },
                    responseType: 'blob',
                }).then(async res => ({
                    blob: await res.blob()
                })).then(resObj => {
                    x++;
                    let thisFilter = undefined;
                    let thisSubFilter = undefined;
                    forEach(Object.keys(this.state.filters), (filter) => {
                        thisFilter = list[k].category.toLowerCase().includes(filter.toLowerCase()) ?
                            filter : thisFilter;
                    });
                    if (thisFilter === 'Modifications') {
                        forEach(Object.keys(this.state.subfilters), (subfilter) => {
                            thisSubFilter = list[k].desc.toLowerCase().includes(subfilter.toLowerCase()) ?
                                subfilter : thisSubFilter;
                        });
                    }
                    let path;
                    switch (by) {
                        case 'filters':
                            path = thisSubFilter === undefined ?
                                zip.folder(list[k].date.split('-')[0]).folder(thisFilter) :
                                zip.folder(list[k].date.split('-')[0])
                                    .folder(thisFilter).folder(thisSubFilter);
                            break;

                        case 'years':
                            path = thisSubFilter === undefined ?
                                zip.folder(thisFilter).folder(list[k].date.split('-')[0]) :
                                zip.folder(thisFilter).folder(thisSubFilter)
                                    .folder(list[k].date.split('-')[0]);
                            break;

                        default:
                            path = zip;
                    }
                    path.file(
                        (list[k].urlPdf.replace('https://www.bodacc.fr/annonce/telecharger/', '')
                            .replace(/%5/g, '-')),
                        new Blob([resObj.blob], { type: 'application/pdf' })
                    );
                    if (x === (list).length) {
                        resolve(zip);
                    }
                });
            }
        })
    }

    async download(by, list) {
        this.setState({ loader : true });
        let zip = await this.makeZip(new JSZip(), by, list);
        this.setState({ loader : false });
        this.setState({ modal: null });
        this.refs['modal'].close();
        zip.generateAsync({ type:'blob' })
            .then(function(content) {
                saveAs(content, "rapports.zip");
            });
    }

    render() {
        if (!this.state.displayed) {
            getFilters(this.state.filters, this.state.subfilters, this.props.list.bodacc);
            this.setState({ displayed: true });
        }

        const cellProps = {
            onMouseEnter: event => this.setState({ hover: true }),
            onMouseLeave: event => this.setState({ hover: false }),
        };

        const identifiers = this.props.entity.preview.identifiers || [];
        const sirens = uniq(identifiers).filter(identifier => identifier.match(/^\d{9}$/));
        const siren = sirens[0];
        let btnStyle = this.state.hover ? Object.assign({ }, styles.notHover, styles.hover) : styles.notHover;
        let myEntities = parseObject(this.props.list.bodacc, this.state.filters, this.state.subfilters);

        return (
            <td>
                <div style={ styles.box }>
                    <div style={ btnStyle } { ...cellProps }>
                        <Views.Common.Button
                            type="raised"
                            color="cold"
                            ref="button"
                            icon={ <Icon type="do.download" /> }
                            label={ 'Créer les dossiers' }
                            onClick={() => this.refs['modal'].open()}
                        />
                        <Views.Modal.Show ref="modal" title={'Créer les dossiers'} onClose={() => this.setState({ modal: null })}>
                            <FormGroup style={ styles.filters } row>
                                { renderFilters(this.handleChange, this.handleSubChange, this.handleMouseHover, this.state, true) }
                            </FormGroup>
                            <FormGroup style={ styles.filters } row>
                                <Views.Common.Tooltip content={'Regrouper les rapports en un dossier | Group all the reports in one folder'}>
                                    <FormControlLabel
                                        style={ styles.checkbox }
                                        control={
                                            <Checkbox
                                                checked={ this.state.filter === '' }
                                                onChange={ (event) => {
                                                    this.setState({ filter: '' });
                                                } }
                                                color="primary"
                                            />
                                        }
                                        label="🗃️ &nbsp;&nbsp;[ 📋 rapports ]"
                                    />
                                </Views.Common.Tooltip>
                                <Views.Common.Tooltip content={'Grouper par année puis par filtre | Sort by year then by type'}>
                                    <FormControlLabel
                                        style={ styles.checkbox }
                                        control={
                                            <Checkbox
                                                checked={ this.state.filter === 'filters' }
                                                onChange={ (event) => {
                                                    this.setState({ filter: 'filters' });
                                                } }
                                                color="primary"
                                            />
                                        }
                                        label="🗃️ &nbsp;&nbsp;[ 📁 années > 📁 filtres > 📋 rapports ]"
                                    />
                                </Views.Common.Tooltip>
                                <Views.Common.Tooltip content={'Grouper par filtre puis par année | Sort by type then by year'}>
                                    <FormControlLabel
                                        style={ styles.checkbox }
                                        control={
                                            <Checkbox
                                                checked={ this.state.filter === 'years' }
                                                onChange={ (event) => {
                                                    this.setState({ filter: 'years' });
                                                } }
                                                color="primary"
                                            />
                                        }
                                        label="🗃️ &nbsp;&nbsp;[ 📁 filtres > 📁 années > 📋 rapports ]"
                                    />
                                </Views.Common.Tooltip>
                            </FormGroup>
                            <Views.Common.Button
                                type="raised"
                                color="cold"
                                ref="button"
                                icon={ <Icon type="do.download" /> }
                                label={ 'Générer pour ' + myEntities.length + ' élément(s)' }
                                disabled={ this.state.loader }
                                onClick={() => this.download(this.state.filter, myEntities)}
                                style={ styles.btn }
                            />
                            { this.state.loader &&
                            <div style={ styles.circle }>
                                <CircularProgress size={ 34} />
                            </div>
                            }
                        </Views.Modal.Show>
                    </div>
                </div>
                <div style={ styles.box }>
                    <Views.Common.Button
                        type="raised"
                        color="cold"
                        ref="button"
                        icon={ <Icon type="do.download" /> }
                        label={ 'Génerer le rapport d\'identité officiel' }
                        onClick={event => siren && window.open(
                            `https://entreprise.data.gouv.fr/api/rncs/v1/fiches_identite/${siren}/pdf`
                        )}
                    >
                    </Views.Common.Button>
                </div>
            </td>
        );
    }
}