import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Selector from './selector';
import t from 'app/translation';
import some from 'lodash/some';
import Icon from 'app/theme/icon';

const styles = {
    container: {},
    title: {
        paddingRight: '20px',
        textAlign: 'right',
        color: 'rgb(158, 158, 158)',
    },
    main: {
        paddingBottom: '20px',
        borderBottom: '1px solid ' + colors.grey200,
    },
    filter: {
        borderTop: '1px solid ' + colors.grey200,
        padding: '20px 0',
    },
    menu: {
        textAlign: 'right',
        padding: '10px',
        color: 'rgb(158, 158, 158)',
        cursor: 'pointer',
    },
    icon: {
        marginTop: '-2px',
        float: 'right',
        fill: colors.grey500,
    },
    externalLinks: {
        textDecoration: 'none',
        color: 'rgb(158, 158, 158)',
        cursor: 'pointer',
        padding: '10px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.news.menu';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        items: PropTypes.array.isRequired,
        state: PropTypes.object,
        style: PropTypes.object,
    };

    static defaultProps = {
        values: {},
    };

    render() {
        const qwantUrl = `https://www.qwant.com/?q="${this.props.name}"&t=news`;
        const googleUrl = `https://www.google.fr/search?q="${this.props.name}"&source=lnms&tbm=nws`;
        const isCategory = some(this.props.items, 'category');

        return (
            <div style={Object.assign({}, styles.container, this.props.style)}>
                <h3 style={styles.title}>{t('common.label.news')}</h3>
                <div style={styles.main}>
                    <Selector
                        type="reliability"
                        choices={[
                            {
                                value: 'o',
                                label: `${t('common.label.sourceOfficial')} (${
                                    this.props.items.filter(item => item.reliability === 'o').length
                                })`,
                            },
                            {
                                value: 'c',
                                label: `${t('common.label.sourceCredible')} (${
                                    this.props.items.filter(item => item.reliability === 'c').length
                                })`,
                            },
                            {
                                value: 'n',
                                label: `${t('common.label.sourceNoise')} (${
                                    this.props.items.filter(item => item.reliability === 'n').length
                                })`,
                            },
                            { value: 'all', label: `${t('common.label.sourceAll')} (${this.props.items.length})` },
                        ]}
                        value={this.props.state ? this.props.state.reliability : 'all'}
                        onChange={value => this.context.updateState({ reliability: value })}
                    />
                </div>
                <div style={styles.menu}>
                    <a style={styles.externalLinks} target="_blank" href={qwantUrl}>
                        Qwant news
                    </a>
                    <Icon style={styles.icon} type={'dna.media.launch'} />
                </div>
                <div style={styles.menu}>
                    <a style={styles.externalLinks} target="_blank" href={googleUrl}>
                        Google news
                    </a>
                    <Icon style={styles.icon} type={'dna.media.launch'} />
                </div>
                {isCategory && (
                    <Selector
                        type="filter"
                        choices={this.computeCategories()}
                        value={this.props.state ? this.props.state.category : undefined}
                        style={styles.filter}
                        onChange={value => this.context.updateState({ category: value })}
                    />
                )}
                <Selector
                    type="filter"
                    choices={this.computeMoments()}
                    value={this.props.state ? this.props.state.moment : undefined}
                    style={styles.filter}
                    onChange={value => this.context.updateState({ moment: value })}
                />
                <Selector
                    type="filter"
                    choices={this.computeSources()}
                    value={this.props.state ? this.props.state.domain : undefined}
                    style={styles.filter}
                    onChange={value => this.context.updateState({ domain: value })}
                />
            </div>
        );
    }

    computeMoments() {
        const moments = [];

        if (_.filter(this.props.items, { moment: 'week' }).length)
            moments.push({ value: 'week', label: t('common.label.thisWeek') });

        if (_.filter(this.props.items, { moment: 'month' }).length)
            moments.push({ value: 'month', label: t('common.label.thisMonth') });

        if (_.filter(this.props.items, { moment: 'year' }).length)
            moments.push({ value: 'year', label: t('common.label.thisYear') });

        if (_.filter(this.props.items, { moment: 'earlier' }).length)
            moments.push({ value: 'earlier', label: t('common.label.earlier') });

        return moments;
    }

    computeSources() {
        return _.map(_.filter(_.uniq(_.map(this.props.items, 'domain'))), domain => ({ value: domain, label: domain }));
    }

    computeCategories() {
        return _.map(_.filter(_.uniq(_.map(this.props.items, 'category'))), category => ({
            value: category,
            label: category,
        }));
    }
}
