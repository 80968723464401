import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Moment from 'moment';
import { inject } from 'mobx-react';
import { toJS } from 'mobx';
import Main from './main';
import Relations from './relations';
import t from 'app/translation';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import xlsx from 'xlsx';
import discovery from './discovery';
import uniq from 'lodash/uniq';

const styles = {
    header: {
        textAlign: 'center',
        color: colors.grey700,
        margin: '20px 0 30px 0',
    },
    strong: {
        color: colors.userCold,
    },
    modal: {
        width: '1400px',
    },
};

@inject((stores) => ({ store: stores.deefind, global: stores.global }))
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.compliance.report';

    static propTypes = {
        store: PropTypes.object,
        global: PropTypes.object,
        entity: PropTypes.object.isRequired,
        stocks: PropTypes.object,
        network: PropTypes.object,
        style: PropTypes.object,
    };

    render() {

        const identifiers = this.props.entity.preview.identifiers || [];
        const sirens = uniq(identifiers).filter(identifier => identifier.match(/^\d{9}$/));
        const siren = sirens[0];

        return (
            <span style={this.props.style}>
                <Views.Common.Button
                    type="raised"
                    color="cold"
                    ref="button"
                    icon={<Icon type="dna.folder" />}
                    label={t('common.label.report')}
                    disabled={!this.props.entity || !this.props.stocks || !this.props.network}
                >
                    <Menu>
                        <MenuItem
                            primaryText="Rapport de conformité"
                            onClick={(event) => {
                                this.refs.modal.open();
                                this.refs.button.closePopover();
                            }}
                        />
                        <MenuItem
                            primaryText="Génerer le rapport d'identité officiel"
                            onClick={event => siren && window.open(
                                `https://entreprise.data.gouv.fr/api/rncs/v1/fiches_identite/${siren}/pdf`
                            )}
                        />

                        {(
                            this.props.entity.type === 'person' &&
                            this.props.global.flags.get().includes('deefind.norkom')
                        ) ? (
                            <MenuItem
                                primaryText="Fiche découverte"
                                onClick={(event) => {
                                    this.downloadDiscovery();
                                }}
                            />
                        ) : null}

                        <Divider />

                        <MenuItem primaryText="Business report" disabled />
                        <MenuItem primaryText="Network report" disabled />
                        <MenuItem primaryText="Property values report" disabled />
                        <Divider />
                        <MenuItem primaryText="Prospect onboarding" disabled />
                        <MenuItem primaryText="Lending file" disabled />
                        <MenuItem primaryText="New team customer meeting" disabled />
                    </Menu>
                </Views.Common.Button>

                <Views.Modal.Show
                    title={`Rapport de conformité  - sécurité financière`}
                    ref="modal"
                    style={styles.modal}
                >
                    <div style={styles.header}>
                        {t('dna.compliance.reportBy')} &nbsp;
                        <span style={styles.strong}>
                            {this.props.global.session.get().user.profile.firstName}{' '}
                            {this.props.global.session.get().user.profile.lastName}
                        </span>
                        &nbsp; {t('dna.compliance.reportOn')} &nbsp;
                        <span style={styles.strong}>{Moment().format('LL')}</span>
                        &nbsp; {t('dna.compliance.reportAt')} &nbsp;
                        <span style={styles.strong}>{Moment().format('LT')}</span>
                    </div>
                    <Main
                        global={this.props.global}
                        store={this.props.store}
                        entity={this.props.entity}
                        network={this.props.network}
                    />
                    <Relations
                        title="Linked companies"
                        relations={this.props.network ? this.props.network.relations.companies : []}
                    />
                </Views.Modal.Show>
            </span>
        );
    }

    downloadDiscovery() {
        const buffer =  discovery(toJS(this.props.entity), toJS(this.props.stocks), toJS(this.props.network));
        buffer.then(buffer => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const file = 'fiche-decouverte.xlsx';

            if (window.navigator.msSaveOrOpenBlob) {
                return window.navigator.msSaveBlob(blob, file);
            }

            const link = window.document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = file;

            if (document.body) {
                document.body.appendChild(link);
            }

            link.click();

            if (document.body) {
                document.body.removeChild(link);
            }
        });
    }
}
